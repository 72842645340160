import React from 'react';
import LocalImage from '../../common/LocalImage';
import styles from './CompanyClients.module.scss';

const CompanyClients = () => {
  const clientsImages = [
    {
      id: 1,
      url: 'partners/l1.png',
      width: 80,
    },
    {
      id: 2,
      url: 'partners/l2.png',
      width: 80,
    },
    {
      id: 3,
      url: 'partners/l3.png',
      width: 100,
    },
    {
      id: 4,
      url: 'partners/l4.png',
      width: 84,
    },
    {
      id: 5,
      url: 'partners/l5.png',
      width: 103,
    },
    {
      id: 6,
      url: 'partners/l6.png',
      width: 86,
    },
    {
      id: 7,
      url: 'partners/l7.png',
      width: 100,
    },
    {
      id: 8,
      url: 'partners/l8.png',
      width: 130,
    },
    {
      id: 9,
      url: 'partners/l9.png',
      width: 124,
    },
    {
      id: 10,
      url: 'partners/l10.png',
      width: 107,
    },
    {
      id: 11,
      url: 'partners/l11.png',
      width: 115,
    },
    {
      id: 12,
      url: 'partners/l12.png',
      width: 124,
    },
  ];
  return (
    <section className={styles.sectionCompanyClients}>
      <div className={styles.clientsWrapper}>
        <h2>Happy clients</h2>
        <div className={styles.clientsText}>Some amazing clients we have worked with along our journey</div>

        <div className={styles.clientsImages}>
          {clientsImages.map(({ id, url, width }) => (
            <div key={id} className={styles.imgWrapper}>
              <div className={styles.imgWrapperInner} style={{ width }}>
                <LocalImage src={url} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CompanyClients;
