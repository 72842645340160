import React from 'react';
import Page from '../components/layout/Page';
import CompanyFeatures from '../components/layout/company/CompanyFeatures';
import CompanyClients from '../components/layout/company/CompanyClients';
import CompanyContact from '../components/layout/company/CompanyContact';

const CompanyPage = () => (
  <Page containerWide title="Company">
    <CompanyFeatures />
    <CompanyClients />
    <CompanyContact />
  </Page>
);

export default CompanyPage;
