import React from 'react';
import LocalImage from '../../common/LocalImage';
import styles from './CompanyFeatures.module.scss';

const CompanyFeatures = () => {
  const AboutImg1Url = 'company/about-1.jpg';
  const AboutImg2Url = 'company/about-2.jpg';
  const AboutImg3Url = 'company/about-3.jpg';
  return (
    <section className={styles.sectionCompanyFeatures}>
      <div className={styles.featuresWrapper}>
        <div className={styles.featuresInner}>
          <h1 className={styles.companyTitle}>
            <strong>About</strong> Us
          </h1>

          <div className={styles.featureList}>
            <div className={styles.featureItem}>
              <div className={styles.featureInfo}>
                <h2 className={styles.featureTitle}>The vision</h2>
                <div className={styles.featureDesc}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                  veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                </div>
              </div>
              <div className={styles.featureImg}>
                <LocalImage src={AboutImg1Url} alt="" />
              </div>
            </div>
            <div className={styles.featureItem}>
              <div className={styles.featureInfo}>
                <h2 className={styles.featureTitle}>The approach</h2>
                <div className={styles.featureDesc}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                  veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                </div>
              </div>
              <div className={styles.featureImg}>
                <LocalImage src={AboutImg2Url} alt="" />
              </div>
            </div>
            <div className={styles.featureItem}>
              <div className={styles.featureInfo}>
                <h2 className={styles.featureTitle}>The process</h2>
                <div className={styles.featureDesc}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                  veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                </div>
              </div>
              <div className={styles.featureImg}>
                <LocalImage src={AboutImg3Url} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.companyBg1} />
        <div className={styles.companyBg2} />
      </div>
    </section>
  );
};

export default CompanyFeatures;
