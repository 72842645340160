import React from 'react';
import { Link } from 'gatsby';
import Button from '../../common/Button';
import styles from './CompanyContact.module.scss';
import ContactImgUrl from '../../../images/company/contact-us.svg';

const CompanyContact = () => {
  return (
    <section className={styles.sectionCompanyContact}>
      <div className={styles.contactWrapper}>
        <div className={styles.contactInner}>
          <div className={styles.contactInfo}>
            <h2>Contact us for any question</h2>
            <div className={styles.contactDesc}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur.
            </div>
          </div>
          <div className={styles.contactImg}>
            <img src={ContactImgUrl} alt="" />
          </div>
          <div className={styles.contactAction}>
            <Link to="contact">
              <Button color="secondary">Contact Us</Button>
            </Link>
          </div>
        </div>

        <div className={styles.companyBg3} />
      </div>
    </section>
  );
};

export default CompanyContact;
